import * as React from 'react'
import {
  Container,
  createStyles,
  makeStyles,
  Typography,
  Grid,
  Theme,
  Collapse,
  Button
} from '@material-ui/core'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { graphql } from 'gatsby'
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@material-ui/icons'
import { useState } from 'react'
import IndexLayout from '../templates/IndexLayout'
import ProfileCard from '../components/ProfileCard'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: '2rem'
    },
    subHeader: {
      fontSize: '1.2rem'
    },
    mdxSection: {
      textAlign: 'center'
    },
    blogListingSection: {
      marginTop: theme.spacing(1)
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    readMoreButton: {
      textDecoration: 'none'
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    icon: {
      fontSize: '4rem'
    }
  })
)

type EmployeeProfile = {
  name: string
  jobTitle: string
  portrait: { childImageSharp: { gatsbyImageData: any } }
  bio: string
  linkedIn: string
}

export default function AboutUs({ data }: any) {
  const profiles: EmployeeProfile[] = data.allMarkdownRemark.edges.map((x: any) => x.node.frontmatter)
  const [readMore, setReadMore] = useState(false)
  const classes = useStyles()
  return (
    <IndexLayout title="About Us">
      <div style={{ height: '10vh' }} />
      <Container maxWidth="md">
        <Grid container justifyContent="center">
          <Grid item sm={12}>
            <Typography className={classes.header} variant="h6">
              About Us
            </Typography>
            <Typography className={classes.subHeader} variant="body1">
              We are Zeti: a FinTech platform based in London and Los Angeles that facilitates pay-per-mile financing for zero or ultra-low emissions vehicles only. <br /> <br />
              Zeti sits at the intersection of fintech, mobility and sustainability, unlocking the potential of technology to revolutionize
              the financing of transport and support the goals of business and society.
              <Collapse in={readMore}>
                <br />
                Our patent pending ZERO platform enables pay-as-you-drive financing, whereby users pay a fixed per-mile cost recorded by
                telematics (think of it as a smart meter for the vehicle). In addition to the environmental benefits of ZERO and ultra-low
                emission vehicles, our pay-as-you-drive model provides the additional benefits of optimized cash flow and cost transparency
                to commercial fleet operators and drivers. <br /> <br />
                Our financing model is proven to deliver risk adjusted returns for financiers at no additional cost to the operators of the
                vehicles. <br /> <br />
                Zeti began by enabling flexible finance for mobility fleets. However, we did not want our work towards facilitating
                sustainability to just stop there. We now supply our pay-as-you-drive financing model to other transport related industries
                such as logistics and public transport. <br /> <br />
                If you’re a fleet operator, financier, investor, wish to become a supplier partner or are just interested in the future of
                mobility then we’d love to hear from you!
              </Collapse>
              <Grid container justifyContent="center">
                <Button onClick={() => setReadMore(!readMore)}>
                  {readMore ? <KeyboardArrowUpRounded className={classes.icon} /> : <KeyboardArrowDownRounded className={classes.icon} />}
                </Button>
              </Grid>
            </Typography>
          </Grid>
          <Grid item sm={11}>
            <Typography className={classes.header} variant="h6">
              Team
            </Typography>
            <Grid className={classes.blogListingSection} container direction="row" justify="center" alignItems="stretch" spacing={5}>
              {profiles
                .filter(profile => profile.jobTitle.toLowerCase() !== 'advisor')
                .map((profile: EmployeeProfile) => {
                  return (
                    <>
                      <ProfileCard
                        profile
                        header={profile.name}
                        subheader={profile.jobTitle}
                        avatar={profile.portrait?.childImageSharp?.gatsbyImageData}
                        ctaIcon={LinkedInIcon}
                        ctaLink={profile.linkedIn}
                        mainText={profile.bio}
                      />
                    </>
                  )
                })}
            </Grid>
            <div style={{ height: '3vh' }} />
            <Typography className={classes.header} variant="h6">
              Advisors
            </Typography>
            <Grid className={classes.blogListingSection} container direction="row" justify="center" alignItems="stretch" spacing={5}>
              {profiles
                .filter(profile => profile.jobTitle.toLowerCase() === 'advisor')
                .map((profile: EmployeeProfile) => {
                  return (
                    <>
                      <ProfileCard
                        profile
                        header={profile.name}
                        subheader={profile.jobTitle}
                        avatar={profile.portrait?.childImageSharp?.gatsbyImageData}
                        ctaIcon={LinkedInIcon}
                        ctaLink={profile.linkedIn}
                        mainText={profile.bio}
                      />
                    </>
                  )
                })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </IndexLayout>
  )
}

export const productsQuery = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "team" } } }, sort: { fields: [frontmatter___index], order: ASC }) {
      edges {
        node {
          frontmatter {
            linkedIn
            bio
            jobTitle
            name
            index
            portrait {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 40, height: 40)
              }
            }
          }
        }
      }
    }
  }
`
